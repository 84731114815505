import { useRegions } from "@with-nx/ecommerce";
import { passwordStrength, useBusinessTypes } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedInput,
  DesignedSelect,
} from "@with-nx/simple-ui/atoms";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { useAuthForm } from "./useAuthForm";

export const RegisterForm = ({
  shouldNotRedirectLogin,
  shouldOpenURL,
  buttonVariant = "primary",
}: {
  shouldNotRedirectLogin?: boolean;
  shouldOpenURL?: string;
  buttonVariant?: string;
}) => {
  const {
    loading,
    name,
    _name,

    email,
    _email,

    _type,

    _location,

    organization,
    _organization,

    password,
    _password,

    repeat,
    _repeat,

    positive,
    error,
    _error,
    register,

    _shouldNotRedirectLogin,
    _shouldOpenURL,
  } = useAuthForm();

  const [params, _params] = useState<URLSearchParams>(new URLSearchParams());

  useEffect(() => {
    const p = new URLSearchParams(window?.location?.search || "");
    _params(p);

    if (shouldNotRedirectLogin !== undefined) {
      _shouldNotRedirectLogin(shouldNotRedirectLogin);
    }

    if (shouldOpenURL) {
      _shouldOpenURL(shouldOpenURL);
    }
  }, []);

  const strength = password.length > 0 ? passwordStrength(password)?.value : "";

  const action = params.get("action");

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      register();
    }
  };

  const handleButtonPress = () => {
    register();
  };

  const types = useBusinessTypes();
  const regions = useRegions();

  return (
    <>
      <form onKeyDown={handleOnKeyDown}>
        <DesignedInput
          label="Full Name"
          value={name}
          change={(name) => _name(name)}
          focus={() => _error("")}
          convert="capitalize"
          native={{
            name: "name",
            cypress: "field-name",
          }}
        />
        <DesignedInput
          label="Email"
          type="email"
          value={email}
          change={(email) => _email(email?.toLocaleLowerCase())}
          focus={() => _error("")}
          convert="lowercase"
          native={{
            name: "email",
            cypress: "field-email",
          }}
        />
        <DesignedInput
          label="Password"
          type="password"
          value={password}
          change={(password) => _password(password)}
          hint={strength.length > 0 ? strength : undefined}
          focus={() => _error("")}
          native={{
            name: "password",
            cypress: "field-password",
          }}
        />
        <DesignedInput
          label="Repeat Password"
          type="password"
          value={repeat}
          change={(repeat) => _repeat(repeat)}
          error={error}
          positive={positive}
          focus={() => _error("")}
          native={{
            name: "repeat-password",
            cypress: "field-repeat-password",
          }}
        />
        <DesignedInput
          label="Organization Name"
          value={organization}
          change={(organization) => _organization(organization)}
          focus={() => _error("")}
          convert="capitalize"
          native={{
            name: "organization",
            cypress: "field-organization",
          }}
        />
        <DesignedSelect
          label="Region"
          change={(i) => _location(Number(i))}
          empty
          options={
            regions?.regionsData?.result?.map((i) => [String(i.id), i.name]) ||
            []
          }
          native={{
            name: "region",
            cypress: "field-region",
          }}
        />
        <DesignedSelect
          label="Business Type"
          change={(i) => _type(Number(i))}
          empty
          options={
            types.types?.map((i) => [String(i.businessType), i.name]) || []
          }
          native={{
            name: "type",
            cypress: "field-type",
          }}
        />
      </form>

      <Box css="d:flex a:center j:flex-end" parse="mt:24">
        <DesignedButton
          label={action || "Create Account"}
          theme={buttonVariant}
          size="small"
          loading={loading}
          press={handleButtonPress}
          properties={{
            native: {
              cypress: "button-register",
            },
          }}
        />
      </Box>
    </>
  );
};
