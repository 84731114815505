import { DesignedButton, DesignedInput, Rule } from "@with-nx/simple-ui/atoms";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Box } from "simple-effing-primitive-layout";

import { LoginForm } from "./LoginForm";
import { useAuthForm } from "./useAuthForm";

export const ForgotPasswordForm = ({ _form, color, buttonVariant }) => {
  const { email, _email, loading, error, positive, forgot } = useAuthForm();

  return (
    <>
      <Box css="d:flex a:center j:space-between" parse="mb:24">
        <Rule rule="ll" color={`var(--${color})`}>
          Forgot Password
        </Rule>
        <Rule
          rule="ls"
          color="var(--font3)"
          style={{
            textDecoration: "underline",
          }}
          press={() => _form("login")}
          native={{
            cypress: "label-sign-in",
          }}
        >
          or sign in
        </Rule>
      </Box>

      <form
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            forgot();
          }
        }}
      >
        <DesignedInput
          label="Email"
          type="email"
          value={email}
          change={(email) => _email(email)}
          error={error}
          positive={positive}
          bottom={0}
          native={{
            name: "email",
            cypress: "field-email",
          }}
        />
      </form>
      <Box css="d:flex a:center j:flex-end" parse="mt:24">
        <DesignedButton
          label="Reset Password"
          theme={buttonVariant}
          size="small"
          loading={loading}
          press={forgot}
          properties={{
            native: {
              cypress: "button-reset-password",
            },
          }}
        />
      </Box>
    </>
  );
};

export const AccessKeyForm = ({ form, _form, color, buttonVariant }) => {
  const { key, _key, loading, error, positive, keyLogin } = useAuthForm();

  return (
    <>
      <Box css="d:flex a:center j:space-between" parse="mb:24">
        <Rule rule="ll" color={`var(--${color})`}>
          Sign In using Access Key
        </Rule>
        <Rule
          rule="ls"
          color="var(--font3)"
          style={{
            textDecoration: "underline",
          }}
          press={() => _form("login")}
          native={{
            cypress: "label-sign-in",
          }}
        >
          or sign in
        </Rule>
      </Box>

      <DesignedInput
        label="Access Key"
        type="string"
        value={key}
        change={(key) => _key(key)}
        error={error}
        positive={positive}
        bottom={0}
        native={{
          name: "key",
          cypress: "field-key",
        }}
      />

      <Box css="d:flex a:center j:flex-end" parse="mt:24">
        <DesignedButton
          label="Sign In"
          theme={buttonVariant}
          size="small"
          loading={loading}
          press={() => keyLogin(form)}
          properties={{
            native: {
              cypress: "button-sign-in",
            },
          }}
        />
      </Box>
    </>
  );
};

export const PublicAccessForm = () => {
  const { token, _token, publicAccessLogin } = useAuthForm();

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (token) {
      publicAccessLogin(token);
    }
  }, []);

  return (
    <>
      <form
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            publicAccessLogin(token);
          }
        }}
      >
        <DesignedInput
          label="Email"
          type="email"
          value={token}
          change={(t) => _token(t)}
          bottom={0}
        />
      </form>
      <Box css="d:flex a:center j:flex-end" parse="mt:24">
        <DesignedButton
          label="Login"
          theme="primary"
          size="small"
          press={() => publicAccessLogin(token)}
        />
      </Box>
    </>
  );
};

export const SignInFormContainer = ({
  form,
  _form,
  shouldNotRedirectLogin,
  shouldOpenURL,
  buttonVariant = "primary",
  color = "primary",
}: {
  form: string;
  _form: Dispatch<
    SetStateAction<
      "login" | "register" | "forgot" | "key" | "global_access_key"
    >
  >;
  shouldNotRedirectLogin?: boolean;
  shouldOpenURL?: string;
  buttonVariant?: string;
  color?: string;
}) => {
  if (form === "forgot")
    return (
      <ForgotPasswordForm
        _form={_form}
        color={color}
        buttonVariant={buttonVariant}
      />
    );
  if (["key", "global_access_key"].includes(form))
    return (
      <AccessKeyForm
        form={form}
        _form={_form}
        color={color}
        buttonVariant={buttonVariant}
      />
    );
  if (form === "login")
    return (
      <>
        <LoginForm
          shouldNotRedirectLogin={shouldNotRedirectLogin}
          shouldOpenURL={shouldOpenURL}
          _form={_form}
          buttonVariant={buttonVariant}
        />
      </>
    );
  if (process?.env?.["NEXT_PUBLIC_ACCESS_PUBLIC"]) return <PublicAccessForm />;

  return undefined;
};
